import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AccountService} from '../_service/account.service';
import {CrmauthorizationService} from '../_service/crmauthorization.service';
import {first} from 'rxjs/operators';
import {GenericResponse} from '../_models/generic-response';
import {CrmRoleOfUser} from '../_models/crm-role-of-user';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  authLinks!: CrmRoleOfUser[];
  newAuthLink: any = [];

  constructor(
    private router: Router,
    private accountService: AccountService,
    private crmAuthService: CrmauthorizationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const tokenValue = this.accountService.tokenValue;
    if (tokenValue) {
      this.crmAuthService.getUserRole().pipe(first()).subscribe((response: GenericResponse<CrmRoleOfUser[]>) => {
        this.authLinks = response.data;
        this.authLinks.forEach(obj => {
          const temp = obj.crmMainSideRelationDtos.map(x => x.path);
          this.newAuthLink.push(...temp);
          setTimeout(() => {
            if (!this.newAuthLink.includes(state.url.split('/')[1])) {
              this.router.navigate(['/401']);
            }
          }, 1000);
        });

      });
      return true;
    } else {
      this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }


}
