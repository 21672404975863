import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {JwtService} from './_service/jwt.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationService} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ReadyDetailComponent} from './order/ready/ready-detail/ready-detail.component';
import {AccordionModule} from 'primeng/accordion';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {TurkposListComponent} from './company/turkpos-list/turkpos-list.component';
import {LoadingModule} from './_components/loading/loading.module';
import {RebateComponent} from './order/rebate/rebate.component';
import {DialogModule} from 'primeng/dialog';
import {Page401Component} from './errorPages/page401/page401.component';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import { DigitCounterComponent } from './_components/digit-counter/digit-counter.component';
import {ChartModule} from 'primeng/chart';
import {LottieModule} from 'ngx-lottie';

// export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
//   hostname: '40.68.63.187',
//   port: 8080,
//   path: '/mqtt',
//   password: 'QLfsRch7sdGQAs',
//   username: 'hg'
// };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ReadyDetailComponent,
    TurkposListComponent,
    RebateComponent,
    Page401Component,
    DigitCounterComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        ConfirmDialogModule,
        NgbModule,
        TableModule,
        ProgressSpinnerModule,
        AccordionModule,
        FormsModule,
        NgSelectModule,
        NgOptionHighlightModule,
        // MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        LoadingModule,
        DialogModule,
        TabViewModule,
        ToastModule,
        CheckboxModule,
        DropdownModule,
        ChartModule,
        LottieModule,
    ],
  providers: [
    JwtService,
    ConfirmationService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
