export const environment = {
  production: true,
  anonymousTokenUrl: 'https://api.hizligeliyo.com/marketing/api/auth/token',
  loginUrl: 'https://api.hizligeliyo.com/crm/api/auth/login',
  regionUrl: 'https://api.hizligeliyo.com/crm/api/region',
  regionDetailUrl: 'https://api.hizligeliyo.com/crm/api/region/detail',
  getCityByIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/city',
  getDistrictByIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/district',
  getNeighborhoodByIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/neighborhood',
  getCityByCountryIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/getCityByCountry',
  getDistrictByCityIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/getDistrictByCity',
  getNeighborhoodByDistrictIdUrl: 'https://api.hizligeliyo.com/crm/api/adress/getNeighborhoodByDistrict',
  getCustomerAddress: 'https://api.hizligeliyo.com/crm/api/adress/getAddresses',
  companyUrl: 'https://api.hizligeliyo.com/crm/api/delivery/company',
  companyAssignedUrl: 'https://api.hizligeliyo.com/crm/api/delivery/company',
  companyNotAssignedUrl: 'https://api.hizligeliyo.com/crm/api/delivery/company',
  getDeliveriesUrl: 'https://api.hizligeliyo.com/crm/api/delivery',
  getCompanyRegionDistanceUrl: 'https://api.hizligeliyo.com/crm/api/delivery/company/region/distance',
  regionCompanyDistanceAddUrl: 'https://api.hizligeliyo.com/crm/api/delivery/region/company/distance/add',
  courierUsersUrl: 'https://api.hizligeliyo.com/crm/api/courier/users',
  courierAddUrl: 'https://api.hizligeliyo.com/crm/api/auth/register',
  courierUserUrl: 'https://api.hizligeliyo.com/crm/api/courier/user',
  courierUserAssigned: 'https://api.hizligeliyo.com/crm/api/courier/assigned',
  courierAssignUrl: 'https://api.hizligeliyo.com/crm/api/courier/assign-company',
  getProductFilter: 'https://api.hizligeliyo.com/crm/api/crm/product/list',
  getCompaniesByDeliveryId: 'https://api.hizligeliyo.com/crm/api/delivery',
  getMerchantNameById: 'https://api.hizligeliyo.com/crm//api/merchant/getbyid',
  getIntegratedStores: 'https://api.hizligeliyo.com/crm/api/Crm/get-integrated-stores',
  updateAmount: 'https://api.hizligeliyo.com/crm/api/Crm/update-basket-quota-amount',
  selectableMainMerchant: 'https://api.hizligeliyo.com/crm/api/Crm/selectable-merchants-to-branch',
  setMainMerchant: 'https://api.hizligeliyo.com/crm/api/Crm/set-main-merchant-to-branch',
  productsStatus: `https://api.hizligeliyo.com/crm/api/merchant/product/status`,
  getStates: 'https://api.hizligeliyo.com/crm/api/product/states',
  getFeatureValues: 'https://api.hizligeliyo.com/crm/api/category/featuresByCategoryId',
  getColors: 'https://api.hizligeliyo.com/crm/api/product/color',
  getSizes: 'https://api.hizligeliyo.com/crm/api/product/size',
  productDetail: 'https://api.hizligeliyo.com/crm/api/crm/product',
  changeCover: `https://api.hizligeliyo.com/crm/api/merchant/product`,
  productUpdateStatus: 'https://api.hizligeliyo.com/crm/api/crm/product/updatestatus',
  getBrands: `https://api.hizligeliyo.com/crm/api/brand`,
  getBrandsRequest: `https://api.hizligeliyo.com/crm/api/brand/brandrequest`,
  addBrandsRequest: `https://api.hizligeliyo.com/crm/api/brand/brandrequest`,
  addNewBrand: `https://api.hizligeliyo.com/crm/brand/add/brandrequest`,
  crmBrand: `https://api.hizligeliyo.com/crm/api/crmbrand`,
  singleProductAdd: 'https://api.hizligeliyo.com/crm/api/merchant/single-product-add',
  getCategoryParent: 'https://api.hizligeliyo.com/crm/api/category/parent',
  getCompany: 'https://api.hizligeliyo.com/crm/api/crm/merchant/register-form',
  category: 'https://api.hizligeliyo.com/crm/api/category',
  findCategoryId: 'https://api.hizligeliyo.com/crm/api/crm/categories/findcategoryidbyname',
  findBrandId: 'https://api.hizligeliyo.com/crm/api/crmBrand/findbrandidbyname',
  findProductId: 'https://api.hizligeliyo.com/crm/api/crm/findproductidbyname',
  findMerchantBranchId: 'https://api.hizligeliyo.com/crm/api/crm/findmerchantbranchidbyname',
  findCampaign: 'https://api.hizligeliyo.com/crm/api/campaign/find-campaign-by-name',
  categoryMarket: 'https://api.hizligeliyo.com/crm/api/MarketPlace/categories',
  getCategoryList: 'https://api.hizligeliyo.com/crm/api/MarketPlace/categories/collection',
  getCategoryListCityGross: 'https://api.hizligeliyo.com/crm/api/marketplace/citygross/categories',
  getCategoryListTrendyol: 'https://api.hizligeliyo.com/crm/api/marketplace/trendyol/categories',
  getCategoryListHizligeliyo: 'https://api.hizligeliyo.com/crm/api/marketplace/hizligeliyo/categories',
  getDetailsByOrderId: 'https://api.hizligeliyo.com/crm/api/crm/orders',
  getOrder: 'https://api.hizligeliyo.com/crm/api/crm/orders',
  getOrderSelectableStatus: 'https://api.hizligeliyo.com/crm/api/crm/order/selectable/status',
  getDisplayedOrderStatus: 'https://api.hizligeliyo.com/crm/api/crm/order/displayedorderstatus',
  orderStatusUpdateUrl: 'https://api.hizligeliyo.com/crm/api/crm/order/update/status',
  orderListToCallCenter: 'https://api.hizligeliyo.com/crm/api/crm/order-list-to-call-center',
  orderDetailToCallCenter: 'https://api.hizligeliyo.com/crm/api/crm/order-informations-to-call-center',
  orderDetailPaymentToCallCenter: 'https://api.hizligeliyo.com/crm/api/crm/call-center-payment-information',
  getTurkpostList: 'https://api.hizligeliyo.com/crm/api/payment/merchant',
  orderPendingAssigment: 'https://api.hizligeliyo.com/crm/api/courier/order-pending-assingment',
  orderPendingAssigmentDetail: 'https://api.hizligeliyo.com/crm/api/courier/order-pending-assingment-detail',
  mapsCourier: 'https://api.hizligeliyo.com/crm/api/courier/maps-courier',
  getCourierInformation: 'https://api.hizligeliyo.com/crm/api/Courier/userinfo',
  courierStatus: 'https://api.hizligeliyo.com/crm/api/courier',
  newOrder: 'https://api.hizligeliyo.com/crm/api/crm/new-orders',
  orderTransfer: 'https://api.hizligeliyo.com/crm/api/courier/order-transfer',
  courierOrderDetail: 'https://api.hizligeliyo.com/crm/api/courier/courier-order-detail/',
  courierDropOrder: 'https://api.hizligeliyo.com/crm/api/Courier/drop-transfer',
  callCourierDrop: 'https://api.hizligeliyo.com/crm/api/Courier/drop-call-courier',
  allCourierOrderCompleted: 'https://api.hizligeliyo.com/crm/api/Courier/all-courier-order-completed',
  ordersCompletedByCourierIdAndDateRange: 'https://api.hizligeliyo.com/crm/api/Courier/orders-completed-by-courier-id-and-date-range',
  orderMerchantBranch: ` https://api.hizligeliyo.com/crm/api/crm/order-list`,
  addOrderAssinged: 'https://api.hizligeliyo.com/crm/api/Courier/addmanuelcourierassign',
  addOrderAssingedV2: 'https://api.hizligeliyo.com/crm/api/Courier/addmultiplemanuelcourierassign',
  getCourirerLocation: 'https://api.hizligeliyo.com/crm/api/courier/courier-request-location',
  merchantUsers: 'https://api.hizligeliyo.com/crm/api/crm/merchant/users',
  features: 'https://api.hizligeliyo.com/crm/api/category/features',
  feature: 'https://api.hizligeliyo.com/crm/api/category/feature',
  featureDelete: 'https://api.hizligeliyo.com/crm/api/category/feature-delete',
  addCategoryCommission: 'https://api.hizligeliyo.com/crm/api/category/merchantbranch/commission',
  getCategoryCommission: 'https://api.hizligeliyo.com/crm/api/category/merchantbranch/commission',
  deleteCategoryCommission: 'https://api.hizligeliyo.com/crm/api/category/merchantbranch/commission',
  crmMerchant: 'https://api.hizligeliyo.com/crm/api/crm/register-form',
  crmMerchantCategory: 'https://api.hizligeliyo.com/crm/api/crm/register-form/category',
  orderConfirm: 'https://api.hizligeliyo.com/crm/api/crm/order/confirm',
  slider: 'https://api.hizligeliyo.com/crm/api/slider',
  sliderPaged: 'https://api.hizligeliyo.com/crm/api/crm/slider-paged',
  crmShowcase: 'https://api.hizligeliyo.com/crm/api/CrmShowcase',
  crmShowcaseItem: 'https://api.hizligeliyo.com/crm/api/CrmShowcase/item',
  crmShowcaseSmartSort: 'https://api.hizligeliyo.com/crm/api/CrmShowcase/smart-sorting',
  communitySendNotification: 'https://api.hizligeliyo.com/crm/api/notification/send-notification',
  communityCustomerAllRegistered: 'https://api.hizligeliyo.com/crm/api/Community/customer-all-registered',
  communityCustomerInfoAllRegistered: 'https://api.hizligeliyo.com/crm/api/Community/customer-info-all-registered',
  communityCustomerDeleteByRegistrationId: 'https://api.hizligeliyo.com/crm/api/Community/customer-delete-by-registration-id',
  featureSets: 'https://api.hizligeliyo.com/crm/api/category/feature-sets',
  featureSet: 'https://api.hizligeliyo.com/crm/api/category/feature-set',
  featureSetDelete: 'https://api.hizligeliyo.com/crm/api/category/feature-sets-delete',
  availableFeatures: 'https://api.hizligeliyo.com/crm/api/category/available-features',
  selectedFeatures: 'https://api.hizligeliyo.com/crm/api/category/selected-features',
  crmProductColor: 'https://api.hizligeliyo.com/crm/api/crm/color',
  crmProductSize: 'https://api.hizligeliyo.com/crm/api/crm/size',
  customerBasket: 'https://api.hizligeliyo.com/crm/api/Crm/getallcustomer',
  customerBasketItem: 'https://api.hizligeliyo.com/crm/api/Crm/getcartbycustomerid',
  comment: 'https://api.hizligeliyo.com/crm/api/crmcomment',
  signalRUrl: 'https://api.hizligeliyo.com/crm/ordertracking',
  orderListTracking: 'https://api.hizligeliyo.com/crm/api/courier/orderlistbytracking',
  crmCustomer: 'https://api.hizligeliyo.com/crm/api/Crm/customer/users',
  contentDeleteUrl: 'https://api.hizligeliyo.com/api/category/category-delete',
  contentUrl: 'https://api.hizligeliyo.com/crm/api/content',
  contentAllUrl: 'https://api.hizligeliyo.com/crm/api/content/content-get-all',
  contentCategoryUrl: 'https://api.hizligeliyo.com/crm/api/content/category',
  contentCategoriesAllUrl: 'https://api.hizligeliyo.com/crm/api/category/categories-all',
  productOfferUrl: 'https://api.hizligeliyo.com/crm/api/product/product-offers',
  productOfferPostUrl: 'https://api.hizligeliyo.com/crm/api/product/add-product-offer',
  productOfferUpdateUrl: 'https://api.hizligeliyo.com/crm/api/product/updateproductoffer',
  productOfferDeleteUrl: 'https://api.hizligeliyo.com/crm/api/product/delete-product-offer',
  getCustomerIdByOrderNumber: 'https://api.hizligeliyo.com/crm/api/Crm/getCustomerId/',
  getOrderRebates: `https://api.hizligeliyo.com/crm/api/Crm/get-all-order-rebates`,
  getMerchantRebateStatus: `https://api.hizligeliyo.com/crm/api/merchant/get-merchant-rebate-status`,
  getRebateStatus: `https://api.hizligeliyo.com/crm/api/customer/rebatestatus`,
  getRebateStatusById: `https://api.hizligeliyo.com/crm/api/merchant/get-rebate-status-by-id`,
  getUserInfo: 'https://api.hizligeliyo.com/crm/api/merchant/get-user-detail-info',
  getCategoriesMarketPlace: 'https://api.hizligeliyo.com/crm/api/MarketPlace/categories/collection',
  categoriesPair: 'https://api.hizligeliyo.com/crm/api/MarketPlace/categories/pair',
  categoriesPairNewVersion: 'https://api.hizligeliyo.com/crm/api/MarketPlace/category/relation',
  categoriesPairDelete: 'https://api.hizligeliyo.com/crm/api/MarketPlace/category/relation',
  brandsPair: 'https://api.hizligeliyo.com/crm/api/MarketPlace/brand/pair',
  brandsPairNewVersion: 'https://api.hizligeliyo.com/crm/api/marketplace/brand/relation',
  searchBrandsPair: 'https://api.hizligeliyo.com/crm/api/MarketPlace/brand',
  getBrandsListCityGross: 'https://api.hizligeliyo.com/crm/api/marketplace/citygross/brands',
  getBrandsListHizligeliyo: 'https://api.hizligeliyo.com/crm/api/marketplace/hizligeliyo/brands',
  brandsPairDelete: 'https://api.hizligeliyo.com/crm/api/MarketPlace/brand/relation',
  campaign: 'https://api.hizligeliyo.com/crm/api/crm/campaign',
  campaignTypes: 'https://api.hizligeliyo.com/crm/api/crm/campaign-types',
  campaignStatistics: 'https://api.hizligeliyo.com/crm/api/crm/statistics',
  campaignMiniLogo: 'https://api.hizligeliyo.com/crm/api/crm/add-mini-logo',
  requestToJoinCampaign: 'https://api.hizligeliyo.com/crm/api/crm/get-participant-merchants',
  approveCampaignRequest: 'https://api.hizligeliyo.com/crm/api/crm/approve-joining-to-campaign',
  specialMerchantCommission: 'https://api.hizligeliyo.com/crm/api/crm/set-special-commission',
  merchantsInCampaign: 'https://api.hizligeliyo.com/crm/api/crm/get-merchants-in-campaign',
  campaignCategoryCommission: 'https://api.hizligeliyo.com/crm/api/crm/categories/campaign-commission',
  participantMerchant: 'https://api.hizligeliyo.com/crm/api/crm/participant-merchants',
  acceptCampaignProduct: 'https://api.hizligeliyo.com/crm/api/crm/accept-campaign-product',
  deleteCampaignCommission: 'https://api.hizligeliyo.com/crm/api/crm/categories/delete-campaign-category-commission',
  campaignProductsPendingApproval: 'https://api.hizligeliyo.com/crm/api/crm/campaign-products-pending-approval',
  getPopups: 'https://api.hizligeliyo.com/crm/api/crm/get-popups',
  addPopup: 'https://api.hizligeliyo.com/crm/api/crm/add-popup',
  updatePopup: 'https://api.hizligeliyo.com/crm/api/crm/update-popup',
  getUserRole: 'https://api.hizligeliyo.com/crm/api/crm/get-user-role',
  getCrmMenus: 'https://api.hizligeliyo.com/crm/api/crm/get-crm-menus',
  getCrmRoles: 'https://api.hizligeliyo.com/crm/api/crm/get-crm-roles',
  getCrmAssociatedMenus: 'https://api.hizligeliyo.com/crm/api/crm/get-crm-associated-menus',
  getOrderPendingAssignments: 'https://api.hizligeliyo.com/crm/api/Courier/get-order-pending-assignments',
  getOrderPendingAssignmentsV2: 'https://api.hizligeliyo.com/crm/api/Courier/get-order-pending-assignments-v2',
  getAssignedOrders: 'https://api.hizligeliyo.com/crm/api/Courier/get-assigned-orders',
  addMerchantTenantRole: 'https://api.hizligeliyo.com/crm/api/Merchant/add-merchant-role',
  getTenantRoles: 'https://api.hizligeliyo.com/crm/api/crm/get-tenant-role',
  deduplicateProduct: 'https://api.hizligeliyo.com/crm/api/crm/deduplicate-product',
  getProductIdByBarcodeId: 'https://api.hizligeliyo.com/crm/api/crm/get-product-id',
  checkDeduplicateProduct: 'https://api.hizligeliyo.com/crm/api/crm/check-is-deduplicate',
  notification: 'https://api.hizligeliyo.com/crm/api/crm/notification',
  notificationInfo: 'https://api.hizligeliyo.com/crm/api/notification',
  deduplicateList: 'https://api.hizligeliyo.com/crm/api/crm/deduplicate-list',
  mainScreen: 'https://api.hizligeliyo.com/crm/api/mainscreen',
  mainScreenSliders: 'https://api.hizligeliyo.com/crm/api/ads/main-screen-sliders',
  mainScreenSlidersAdd: 'https://api.hizligeliyo.com/crm/api/ads/main-screen-sliders',
  getByIdMainScreenSlider: 'https://api.hizligeliyo.com/crm/api/ads/main-screen-slider-by-id',
  getpackageTypes: 'https://api.hizligeliyo.com/crm/api/crm/package-types',
  addpackageTypes: 'https://api.hizligeliyo.com/crm/api/crm/add-package-type',
  getallCities: 'https://api.hizligeliyo.com/crm/api/adress/all-cities',
  getRoadDirectionPrices: 'https://api.hizligeliyo.com/crm/api/crm/road-direction-prices',
  getRoadDirectionPricesById: 'https://api.hizligeliyo.com/crm/api/crm/road-direction-prices-byid',
  updateCourierPicture: 'https://api.hizligeliyo.com/crm/api/Courier/update-picture',
  getMongoCouriers: 'https://api.hizligeliyo.com/crm/api/Courier/mongolist',
  updateCourierPreferStatus: 'https://api.hizligeliyo.com/crm/api/Courier/update/preferstatus',
  getShipments: 'https://api.hizligeliyo.com/crm/api/callcourier/call-courier-list',
  getShipmentsDetail: 'https://api.hizligeliyo.com/crm/api/CallCourier/getbyid',
  getShipmentPending: 'https://api.hizligeliyo.com/crm/api/Courier/call-courier-pending-assingment',
  addShipmentAssign: 'https://api.hizligeliyo.com/crm/api/Courier/addmanuelcallcourierassign',
  getAssignedShipment: 'https://api.hizligeliyo.com/crm/api/Courier/get-assigned-shipments',
  shipmentTransfer: 'https://api.hizligeliyo.com/crm/api/Courier/call-courier-transfer',
  getAllExchangeRate: 'https://api.hizligeliyo.com/crm/api/Currency',
  updateAllExchangeRate: 'https://api.hizligeliyo.com/crm/api/Currency/update',
  generateIntegration: 'https://api.hizligeliyo.com/crm/api/merchant/register-integration',
  getAllFranchiseRequest: 'https://api.hizligeliyo.com/crm/api/crm/franchise/getall',
  getAllFranchiseRequestById: 'https://api.hizligeliyo.com/crm/api/crm/franchise/byId',
  approveFranchise: 'https://api.hizligeliyo.com/crm/api/crm/approve-franchise-request',
  appMode: 'https://api.hizligeliyo.com/crm/api/crm/maintenance',
  generalMessage: 'https://api.hizligeliyo.com/crm/api/community/ws-general-send-message',
  getAssignedCourier: 'https://api.hizligeliyo.com/crm/api/Courier/get-assigned-couriers',
  getDeliveryCompany: 'https://api.hizligeliyo.com/crm/api/Crm/getdeliverycompany',
  getDeliveryType: 'https://api.hizligeliyo.com/crm/api/Crm/getdeliverytype',
  getCustomerV2: 'https://api.hizligeliyo.com/crm/api/crm/customer/info',
  getDashboard: 'https://api.hizligeliyo.com/crm/api/Community/get-crm-dashboard',
  merchantExcel: 'https://api.hizligeliyo.com/crm/api/Crm/merchant-info-excel',
  getCrmUsers: 'https://api.hizligeliyo.com/crm/api/Crm/crmuser-list',
  getCrmUserRoles: 'https://api.hizligeliyo.com/crm/api/Crm/crmroles',
  addCrmRole: 'https://api.hizligeliyo.com/crm/api/Crm/create-role',
  deleteCrmRole: 'https://api.hizligeliyo.com/crm/api/Crm/delete-role',
  getRolesOnUser: 'https://api.hizligeliyo.com/crm/api/Crm/crmuserrole',
  assignRoleToUser: 'https://api.hizligeliyo.com/crm/api/Crm/assing-users-role',
  deleteRoleFromUser: 'https://api.hizligeliyo.com/crm/api/Crm/deleterolefromuser',
  checkMerchant: 'https://api.hizligeliyo.com/crm/api/merchantbranch/check',
  checkTrendyolIntegration: 'https://api.hizligeliyo.com/crm/api/marketplace/exists-trendyol-integration',
  registerCity: `https://api.hizligeliyo.com/marketing/api/payment/cities`,
  getMerchantDelivery : 'https://api.hizligeliyo.com/crm/api/crm/list',
  deliveryMerchantCompanies: `https://api.hizligeliyo.com/crm/api/delivery/merchant-companies`,
  merchantBranchDeliveryCompanyPostOrDeleteOrUrl: `https://api.hizligeliyo.com/crm/api/merchantbranchdeliverycompany`,
  deliveryMerchantCompanyExpress: `https://api.hizligeliyo.com/crm/api/delivery/merchant-express-companies`,
  getMerchantAddress: `https://api.hizligeliyo.com/internal/api/MerchantBranch/addresses`,
  addressGetByIdUrl: `https://api.hizligeliyo.com/crm/api/adress/getbyid`,
  getMerchantsDocuments: 'https://api.hizligeliyo.com/crm/api/crm/get-merchant-documents',
  updateMerchantsDeliveryStatus: 'https://api.hizligeliyo.com/crm/api/crm/crm-merchant-branch-delivery-company',
  updateMerchantsIban: 'https://api.hizligeliyo.com/crm/api/Merchant/branch/update/iban',
  getMerchantExpressStatus : 'https://api.hizligeliyo.com/crm/api/merchant/branch/status',
  callCourierInfo : 'https://api.hizligeliyo.com/crm/api/Courier/courier-information-call-center',
  getCourierList : 'https://api.hizligeliyo.com/crm/api/Courier/courierOrder-info',
  getCourierVesting : 'https://api.hizligeliyo.com/crm/api/Courier/courier-vesting',
  getCourierVestingDetail : 'https://api.hizligeliyo.com/crm/api/Courier/crm-courier-vesting-detail',
  getMainMerchant: 'https://api.hizligeliyo.com/crm/api/Crm/main-merchant',
  getRelationCourier: 'https://api.hizligeliyo.com/crm/api/Courier/crm-getcourier',
  getCouponCode: 'https://api.hizligeliyo.com/crm/api/crm/discount-code-campaigns-list',
  getPaymentReminding: 'https://api.hizligeliyo.com/crm/api/crm/order-last-vesting',
  getOverPaymentReminding: 'https://api.hizligeliyo.com/crm/api/crm/overdue-order-vesting',
  getTimeZoneOrder: 'https://api.hizligeliyo.com/crm/api/crm/get-delivery-calendar-of-week-by-city',
  updateTimeZoneOrder: 'https://api.hizligeliyo.com/crm/api/crm/delivery-calendar-of-week-by-city',
  checkOrderCompleted: 'https://api.hizligeliyo.com/crm/api/crm/order-completed-check',
  getPaymentCustomerInfo: 'https://api.hizligeliyo.com/crm/api/crm/order-customer-detail',
  getHourList: 'https://api.hizligeliyo.com/crm/api/delivery/get-hours-of-day',
  savePrefix: 'https://api.hizligeliyo.com/crm/api/Elastic/search-prefix',
  getPopularPrefix: 'https://api.hizligeliyo.com/crm/api/Elastic/popular-search-prefix',
  getCargos: 'https://api.hizligeliyo.com/crm/api/crm/get-delivery-companies',
  getDesies: 'https://api.hizligeliyo.com/crm/api/delivery/company/desi',
  getPreProv: 'https://api.hizligeliyo.com/crm/api/crm/order-list-pre-prov',
  savePreProv: 'https://api.hizligeliyo.com/crm/api/crm/close-pre-prov',
  sortPrefix: 'https://api.hizligeliyo.com/crm/api/Elastic/search-prefix-update-sort'
};
