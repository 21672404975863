import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Token} from '../_models/token';
import {environment} from '../../environments/environment';
import {GenericResponse} from '../_models/generic-response';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {JwtService} from './jwt.service';
import {MerchantUsersResponse} from '../_models/merchant-users-response';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  tokenSubject!: BehaviorSubject<Token>;
  crmToken!: Observable<Token>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True'
    })
  };

  // private crmTokenSubject: BehaviorSubject<Token>;
  // public crmToken: Observable<Token>;
  public anonymousToken: Observable<Token>;
  private anonymousTokenSubject: BehaviorSubject<Token>;

  constructor(private http: HttpClient, private router: Router, private jwtService: JwtService) {
    this.anonymousTokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('anonymousToken') as string));
    this.anonymousToken = this.anonymousTokenSubject.asObservable();
    //
    // this.crmTokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('merchantToken') as string));
    // this.crmToken = this.crmTokenSubject.asObservable();

    this.tokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('crmToken') as string));
    this.crmToken = this.tokenSubject.asObservable();
  }

  public get tokenValue(): Token {
    return this.tokenSubject.value;
  }

  public get anonymousTokenValue(): Token {
    return this.anonymousTokenSubject.value;
  }

  // public get merchantTokenValue(): Token {
  //   return this.crmTokenSubject.value;
  // }

  // public get merchantIdTokenValue(): IdToken {
  //   return this.jwtService.DecodeToken(this.merchantTokenValue.idToken) as unknown as IdToken;
  // }

  loginAnonymous(): Observable<Token> {
    return this.http.get<GenericResponse<Token>>(`${environment.anonymousTokenUrl}`)
      .pipe(map(response => {
        if (response) {
          localStorage.setItem('anonymousToken', JSON.stringify(response.data));
          this.anonymousTokenSubject.next(response.data);
        }
        return response.data;
      }));
  }

  login(email: string, password: string): any {
    return this.http.post<GenericResponse<Token>>(`${environment.loginUrl}`, {email, password})
      .pipe(map(response => {
        localStorage.setItem('crmToken', JSON.stringify(response.data));
        this.tokenSubject.next(response.data);
        return response;
      }));
  }

  logout(): void {
    localStorage.removeItem('crmToken');
    // @ts-ignore
    this.tokenSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  getMerchantUsers(page: number, pageSize: number): Observable<GenericResponse<MerchantUsersResponse[]>> {
    return this.http.get<GenericResponse<MerchantUsersResponse[]>>(`${environment.merchantUsers}?page=${page}&page=${pageSize}`);
  }
}
