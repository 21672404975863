import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from './loading.component';
import {LottieModule} from 'ngx-lottie';

export function playerFactory() { // add this line
  return import('lottie-web'); // add this line
}

@NgModule({
  declarations: [LoadingComponent],
  exports: [
    LoadingComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({player: playerFactory})
  ]
})
export class LoadingModule {
}
