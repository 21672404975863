import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Token} from './_models/token';
import {AccountService} from './_service/account.service';
import {JwtService} from './_service/jwt.service';
import {IdToken} from './_models/id-token';
import {DOCUMENT, registerLocaleData} from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import {ActivatedRoute, Router} from '@angular/router';
import {CrmauthorizationService} from './_service/crmauthorization.service';
import {CrmRole} from './_models/crm-role';
import {first} from 'rxjs/operators';
import {GenericResponse} from './_models/generic-response';
import {CrmRoleOfUser} from './_models/crm-role-of-user';
import {CrmMenu} from './_models/crm-menu';
import {CrmAuthAssociateMenu} from './_models/crm-auth-associate-menu';
import {NotificationService} from './_service/notification.service';
import {Notification} from './_models/notification';
import {AlertifyService} from './_service/alertify.service';
import {DashboardService} from './_service/dashboard.service';
import {AnimationOptions} from 'ngx-lottie';


declare var $: any;

registerLocaleData(localeTr, 'tr');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, AfterViewInit {
  title = 'crm-app';

  token!: Token;
  // anonymousToken!: Token;

  roles!: CrmRole[];
  userRoles!: CrmRoleOfUser[];
  crmMenus!: CrmMenu[];
  crmAuthAssociateMenu!: CrmAuthAssociateMenu[];
  authLinks !: string[];

  notifications!: Notification[];
  notification!: any;
  notificationCount!: number;

  comingPayList: any[] = [];
  overPayList: any[] = [];
  dialogPayment = false;

  name = 'Hızlıgeliyo';
  imageUrl = '../assets/images/users/user-5.jpg';

  hiddenMenu = false;
  crmUserId!: any;
  crmMenu!: any[];
  pathLottie!: string;
  optionsLottie: AnimationOptions = {};


  constructor(private accountService: AccountService,
              private crmAuthService: CrmauthorizationService,
              private jwtService: JwtService,
              private router: Router,
              private alertify: AlertifyService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private dashboardService: DashboardService,
              @Inject(DOCUMENT) private document: Document) {
    this.pathLottie = '/assets/data/erroranim.json';
  }

  ngOnInit(): void {
    if (window.location.hash === '#/account/login' || window.location.hash === '#/account/login?returnUrl=%2F') {

    } else {
      this.getAllNotification();
      this.notiCount();
    }

    this.accountService.crmToken.subscribe((x: Token) => {
      if (x?.idToken) {
        const idToken = this.jwtService.DecodeToken(x.idToken) as unknown as IdToken;
        this.name = idToken.name;
        this.imageUrl = idToken.picture;
      } else {
        setTimeout(() => {
          this.router.navigate(['account/login']);
        }, 1000);
      }
      return this.token = x;
    });
    this.optionsLottie = {
      path: this.pathLottie
    };
  }


  logout(): void {
    this.accountService.logout();
  }


  ngAfterViewInit(): void {
    this.crmAuthService.getUserRole()
      .pipe(first())
      .subscribe((response: GenericResponse<CrmRoleOfUser[]>) => {
        setTimeout(() => {
          this.userRoles = response.data;
          this.userRoles.sort((a, b) => Number(a.sort) - Number(b.sort));
          this.userRoles.forEach(value => {
            this.authLinks = value.crmMainSideRelationDtos.map(x => x.route);
          });
        }, 500);
      });
    this.getPaymentReminding();
    this.getOverPaymentReminding();
  }

  getAllNotification(): void {
    this.notificationService.getAll(1, 5, true).pipe(first()).subscribe((response: GenericResponse<any>) => {
      this.notifications = response.data;
    });
  }

  markAsRead(): void {
    let ids;
    if (this.notifications.map(c => c.id).length > 1) {
      ids = this.notifications.map(c => c.id).join(',');
    } else {
      ids = this.notifications.map(c => c.id);
    }
    this.notificationService.markAsRead(ids.toString()).pipe(first()).subscribe((response: GenericResponse<any>) => {
      if (response.isError) {
        this.alertify.error(response.errorMessageList.join('\r\n'));
      } else {
        this.notiCount();
        setTimeout(() => {
          this.notifications.forEach(c => c.isRead = true);
        }, 750);
      }
    });

  }

  notiCount(): void {
    this.notificationService.getCount().pipe(first()).subscribe((response: GenericResponse<any>) => {
      this.notificationCount = response.total;
    });
  }

  notificationById(id: number): void {
    // const orderId = correlationId.split(',')[1];
    // this.router.navigate(['/order/detail/' + orderId]);
    this.notificationService.getById(id).pipe(first()).subscribe((response: GenericResponse<any>) => {
      this.notification = response.data;
      this.notiCount();
      this.getAllNotification();
    });
  }

  getPaymentReminding(): void {
    this.dashboardService.getPaymentReminding().pipe(first())
      .subscribe((response: GenericResponse<any>) => {
        this.comingPayList = response.data.orderNumber;
        // if (this.comingPayList.length > 0) {
        //   this.dialogPayment = true;
        // }
      });
  }

  getOverPaymentReminding(): void {
    this.dashboardService.getOverPaymentReminding().pipe(first())
      .subscribe((response: GenericResponse<any>) => {
        this.overPayList = response.data.orderNumber;
        // if (this.comingPayList.length > 0) {
        //   this.dialogPayment = true;
        // }
      });
  }

  paymentDialog(): void {
    this.dialogPayment = true;
  }

  detailPayment(item: string): void {
    this.router.navigate(['order/order-merchant-branch/detail/' + item]);
    this.dialogPayment = false;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
