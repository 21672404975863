<div class="row">
  <div class="col-12">
    <h3>Dashboard </h3>
    <hr class="mt-0 mb-2">
  </div>
</div>


<div *ngIf="dashboardData">
  <div class="row">
    <div class="col-12">
      <div class="card mb-1">
        <div class="row card-body pb-0">
          <div class="col-sm-12 col-md-6">
            <a routerLink="/company/list">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fas fa-check-double customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Yeni Gelen Mağaza Başvuruları</p>
                    <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.merchantReferencesCount"
                                       [duration]="1000"></app-digit-counter>
                  </span></h3>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="/company/franchise-request">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fas fa-hands-helping customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Yeni Gelen Franchise Başvuruları</p>
                    <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.franchiseReferencesCount"
                                       [duration]="1000"></app-digit-counter>
                  </span></h3>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="/order">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fas fa-shopping-basket customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Toplam Sipariş Sayısı</p>
                    <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.totalOrderCount" [duration]="1000"></app-digit-counter>
                  </span></h3>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="customer/list">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fas fa-users customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Toplam Kullanıcı Sayısı</p>
                    <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.totalUserCount" [duration]="1000"></app-digit-counter>
                  </span></h3>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="/company/company-info">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fa-store fas customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Toplam Mağaza Sayısı</p>
                    <div class="d-flex align-items-center">
                      <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.totalMerchantCount" [duration]="1000"></app-digit-counter>
                  </span></h3>
                      <div><small> (Trendyol: {{dashboardData.totalTrendyolMerchantIntegrationCount}}-
                        HG: {{dashboardData.totalMerchantIntegration}})</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="products/list">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fas fa-boxes customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Toplam Aktif Ürün Sayısı</p>
                    <div class="d-flex align-items-center">
                      <h3 class="text-dark my-0"><span data-plugin="counterup">
<!--                        {{dashboardData.totalActiveProductCount | number: '1.0-2': 'tr'}}-->
                       <app-digit-counter [digit]="dashboardData.totalActiveProductCount"
                                          [duration]="1000"></app-digit-counter>
                  </span></h3>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="couriers/courier">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fa-biking fas customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Toplam Kurye Sayısı </p>
                    <div class="d-flex align-items-center">
                      <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.totalCourierCount" [duration]="1000"></app-digit-counter>
                  </span></h3>
                      <div><small> (HG: {{dashboardData.totalMarketPlaceCourierCount}} - KG
                        : {{dashboardData.totalCallCourierCount}} - KTA : {{dashboardData.totalKTACourierCount}}
                        )</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-sm-12 col-md-6">
            <a routerLink="campaign/list">
              <div class="card-box">
                <div class="d-flex">
                  <div><i class="fa-tags fas customfas"></i></div>
                  <div class="ml-2">
                    <p class="mb-0 text-dark">Aktif Kampanya Sayısı</p>
                    <h3 class="text-dark my-0"><span data-plugin="counterup">
                    <app-digit-counter [digit]="dashboardData.totalActiveCampaingCount"
                                       [duration]="1000"></app-digit-counter>
                  </span></h3>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="dashboardData.monthlyOrder.length > 0">
    <div class="col-sm-12 col-md-12">
      <div class="card mb-1">
        <div class="card-body pb-0">
          <p-chart type="bar" [data]="monthOrder" [options]="monthOrderOptions" height="240"></p-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="dashboardData.productCountByCategories.length > 0">
    <div class="col-sm-12 col-md-12">
      <div class="card mb-1">
        <div class="card-body pb-0">
          <p-chart type="bar" [data]="productCategories" [options]="productCategoriesOptions" height="1900"></p-chart>
        </div>
      </div>
    </div>
  </div>

</div>

<!--<p-dialog [(visible)]="dialogPayment" [modal]="true"-->
<!--          [style]="{height: '600px'}"-->
<!--          header="Hakediş bekleyen siparişler"-->
<!--          styleClass="p-fluid">-->
<!--  <ng-template pTemplate="content">-->

<!--    <div class="alert alert-danger d-flex align-items-center"> <ng-lottie [options]="optionsLottie" height="48px"></ng-lottie><a class="text-dark"-->
<!--                                                                         routerLink='order/order-merchant-branch'> {{overPayList.length}}-->
<!--      adet hakediş verilmemiş ve süresi geçmiş sipariş mevcut</a></div>-->
<!--    <table class="table table-striped table-sm table-bordered">-->
<!--      <thead>-->
<!--      <th colspan="2" class="text-warning">-->
<!--        Hakediş verilmesi gereken ve 5 günden daha az süresi kalan siparişler-->
<!--      </th>-->
<!--      </thead>-->
<!--      <thead>-->
<!--      <th>Sipariş No</th>-->
<!--      <th class="text-center" style="width: 1rem">Detay</th>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr *ngFor="let item of comingPayList">-->
<!--        <td>-->
<!--          <span class="text-dark font-weight-semibold">#{{item}}</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <button class="btn btn-sm btn-info" routerLink='order/order-merchant-branch/detail/{{item}}'><i-->
<!--            class="far fa-edit"></i></button>-->
<!--        </td>-->
<!--      </tr>-->
<!--    </table>-->
<!--  </ng-template>-->
<!--</p-dialog>-->





