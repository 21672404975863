import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse} from '../_models/generic-response';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) {
  }


  getAll(page: number, pageSize: number, isRead: boolean): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.notification}?page=${page}&pageSize=${pageSize}&isRead=${isRead}`);
  }

  getCount(): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.notification}/count`);
  }

  getById(id: number): Observable<GenericResponse<any>> {
    return this.http.get<GenericResponse<any>>(`${environment.notificationInfo}/${id}`);
  }

  markAsRead(ids: string): Observable<GenericResponse<any>> {
    return this.http.post<GenericResponse<any>>(`${environment.notificationInfo}/mark-as-read?ids=${ids}`, ids);
  }


}
