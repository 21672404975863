import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AccountService} from '../_service/account.service';
import {Token} from '../_models/token';
import {ActivatedRoute, Router} from '@angular/router';
import {Dashboard, ProductCountByCategories} from '../_models/dashboard';
import {DashboardService} from '../_service/dashboard.service';
import {first} from 'rxjs/operators';
import {GenericResponse} from '../_models/generic-response';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  token!: Token;
  dashboardData!: Dashboard;
  monthOrder: any;
  monthOrderOptions: any;
  productCategories: any;
  productCategoriesOptions: any;
  randomColor: any[] = [];
  randomColor2: any[] = [];
  // comingPayList: any[] = [];
  // overPayList: any[] = [];
  // dialogPayment = false;
  pathLottie!: string;
  optionsLottie: AnimationOptions = {};


  constructor(private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              private dashboardService: DashboardService
  ) {
    this.token = this.accountService.tokenValue;
    this.pathLottie = '/assets/data/erroranim.json';
  }

  ngOnInit(): void {
    // this.redirect();
    this.getDashboardData();
    this.optionsLottie = {
      path: this.pathLottie
    };
  }

  ngAfterViewInit(): void {
    // this.router.navigate(['/products/list'], {relativeTo: this.route});
  }

  generateColor(): string {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color + '50';
  }

  getDashboardData(): void {
    this.dashboardService
      .getDashboard()
      .pipe(first())
      .subscribe((response: GenericResponse<Dashboard>) => {
        this.dashboardData = response.data;

        const productCatName = this.dashboardData.productCountByCategories.map(x => x.key);
        const productCatCount = this.dashboardData.productCountByCategories.map(x => x.docCount);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < 500; i++) {
          this.randomColor.push(this.generateColor());
          this.randomColor2.push(this.generateColor());
        }
        const monthOrderCount = this.dashboardData.monthlyOrder.map(x => x.count);
        const monthOrderName = this.dashboardData.monthlyOrder.map(x => x.nameOfMonth);
        this.monthOrder = {
          labels: monthOrderName,
          datasets: [
            {
              label: 'Sipariş Sayıları',
              backgroundColor: this.randomColor,
              data: monthOrderCount
            }
          ]
        };
        this.monthOrderOptions = {
          title: {
            display: true,
            text: 'Aylık Siparişler',
            fontSize: 12
          },
          legend: {
            position: 'bottom'
          }
        };
        this.productCategories = {
          labels: productCatName,
          datasets: [
            {
              label: 'Kategori Bazlı Ürün Sayıları',
              backgroundColor: this.randomColor2,
              data: productCatCount
            }
          ]
        };
        this.productCategoriesOptions = {
          indexAxis: 'y',
          scales: {
            x: {
              type: 'logarithmic',
              bounds: 'ticks',
              // NOT A PROP FOR type: "logarithmic"
              // beginAtZero: true, //
              min: 0,
              max: 3000000, // require
              ticks: {
                display: true,
                max: 100,
                min: 20,
                maxTicksLimit: 101,
              }
            }
          },
        };
      });
  }

  // getPaymentReminding(): void {
  //   this.dashboardService.getPaymentReminding().pipe(first())
  //     .subscribe((response: GenericResponse<any>) => {
  //       this.comingPayList = response.data.orderNumber;
  //       if (this.comingPayList.length > 0) {
  //         this.dialogPayment = true;
  //       }
  //     });
  // }
  //
  // getOverPaymentReminding(): void {
  //   this.dashboardService.getOverPaymentReminding().pipe(first())
  //     .subscribe((response: GenericResponse<any>) => {
  //       this.overPayList = response.data.orderNumber;
  //       this.payListCount = this.comingPayList.length;
  //       if (this.comingPayList.length > 0) {
  //         this.dialogPayment = true;
  //       }
  //     });
  // }



}
