import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';

@Component({
  selector: 'app-digit-counter',
  templateUrl: './digit-counter.component.html',
  styleUrls: ['./digit-counter.component.css']
})
export class DigitCounterComponent implements AfterViewInit, OnChanges {
  @Input() duration: number | undefined;
  @Input() digit: number | undefined;
  @Input() steps: number | undefined;
  @ViewChild('animatedDigit', {static: true}) animatedDigit: ElementRef | undefined;

  animateCount(): void {
    if (!this.duration) {
      this.duration = 1000;
    }

    if (typeof this.digit === 'number') {
      this.counterFunc(this.digit + 1 , this.duration, this.animatedDigit);
    }
  }

  counterFunc(endValue: any, durationMs: any, element: any): void {
    if (!this.steps) {
      this.steps = 20;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step(): void {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit(): void {
    if (this.digit) {
      this.animateCount();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.digit) {
      this.animateCount();
    }
  }
}
