import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CrmRoleOfUser} from '../_models/crm-role-of-user';
import {GenericResponse} from '../_models/generic-response';
import {environment} from '../../environments/environment';
import {CrmRole} from '../_models/crm-role';
import {CrmMenu} from '../_models/crm-menu';
import {CrmAuthAssociateMenu} from '../_models/crm-auth-associate-menu';

@Injectable({
  providedIn: 'root'
})

export class CrmauthorizationService {

  constructor(private httpClient: HttpClient) {
  }

  getUserRole(): Observable<GenericResponse<CrmRoleOfUser[]>> {
    return this.httpClient.get<GenericResponse<CrmRoleOfUser[]>>(`${environment.getUserRole}`);
  }

  getCrmRoles(): Observable<GenericResponse<CrmRole[]>> {
    return this.httpClient.get<GenericResponse<CrmRole[]>>(`${environment.getCrmRoles}`);
  }

  getCrmMenus(): Observable<GenericResponse<CrmMenu[]>> {
    return this.httpClient.get<GenericResponse<CrmMenu[]>>(`${environment.getCrmMenus}`);
  }

  getCrmAssociatedMenus(): Observable<GenericResponse<CrmAuthAssociateMenu[]>> {
    return this.httpClient.get<GenericResponse<CrmAuthAssociateMenu[]>>(`${environment.getCrmAssociatedMenus}`);
  }

}
