import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_helpers/auth.guard';
import {HomeComponent} from './home';
import {Page401Component} from './errorPages/page401/page401.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const deliveryModule = () => import('./delivery/main/delivery.module').then(x => x.DeliveryModule);
const productModule = () => import('./product/main/product.module').then(x => x.ProductModule);
const brandModule = () => import('./brand/main/brand.module').then(x => x.BrandModule);
const categoryModule = () => import('./category/main/category.module').then(x => x.CategoryModule);
const companyModule = () => import('./company/main/company.module').then(x => x.CompanyModule);
const contentModule = () => import('./content/main/content.module').then(x => x.ContentModule);
const orderModule = () => import('./order/main/order.module').then(x => x.OrderModule);
const couriersModule = () => import('./couriers/main/couriers.module').then(x => x.CouriersModule);
const paymentModule = () => import('./payment/main/payment.module').then(x => x.PaymentModule);
const sliderModule = () => import('./slider/main/slider.module').then(x => x.SliderModule);
const showcaseModule = () => import('./showcase/main/showcase.module').then(x => x.ShowcaseModule);
const pushModule = () => import('./push/main/push.module').then(x => x.PushModule);
const customerModule = () => import('./customer/main/customer.module').then(x => x.CustomerModule);
const customerRegisterModule = () => import('./customer/register-customer/main/register-customer.module').then(x => x.RegisterCustomerModule);
const campaingModule = () => import('./campaign/main/campaign.module').then(x => x.CampaignModule);
const popupModule = () => import('./popup/main/popup.module').then(x => x.PopupModule);
const integrationModule = () => import('./integration/main/integration.module').then(x => x.IntegrationModule);
const notificationModule = () => import('./notification/main/notification.module').then(x => x.NotificationModule);
const mainScreenModule = () => import('./mainScreen/main/main.module').then(x => x.MainModule);
const callCouriersModule = () => import('./call-couriers/main/call-couriers.module').then(x => x.CallCouriersModule);
const statisticsModule = () => import('./statistics/main/statistics.module').then(x => x.StatisticsModule);
const courierModule = () => import('./statistics/courier/main/courier.module').then(x => x.CourierModule);
const appSettingsModule = () => import('./app-settings/main/app-settings-module').then(x => x.AppSettingsModule);
const managementModule = () => import('./management/main/management.module').then(x => x.ManagementModule);

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'dashboard', component: HomeComponent},
  {path: 'account', loadChildren: accountModule},
  {path: 'delivery', loadChildren: deliveryModule, canActivate: [AuthGuard]},
  {path: 'products', loadChildren: productModule, canActivate: [AuthGuard]},
  {path: 'brands', loadChildren: brandModule, canActivate: [AuthGuard]},
  {path: 'category', loadChildren: categoryModule, canActivate: [AuthGuard]},
  {path: 'company', loadChildren: companyModule, canActivate: [AuthGuard]},
  {path: 'content', loadChildren: contentModule, canActivate: [AuthGuard]},
  {path: 'order', loadChildren: orderModule, canActivate: [AuthGuard]},
  {path: 'couriers', loadChildren: couriersModule, canActivate: [AuthGuard]},
  {path: 'order-merchant-branch', loadChildren: paymentModule, canActivate: [AuthGuard]},
  {path: 'slider', loadChildren: sliderModule, canActivate: [AuthGuard]},
  {path: 'showcase', loadChildren: showcaseModule, canActivate: [AuthGuard]},
  {path: 'push', loadChildren: pushModule, canActivate: [AuthGuard]},
  {path: 'customer', loadChildren: customerModule, canActivate: [AuthGuard]},
  {path: 'campaign', loadChildren: campaingModule, canActivate: [AuthGuard]},
  {path: 'integration', loadChildren: integrationModule, canActivate: [AuthGuard]},
  {path: 'popup', loadChildren: popupModule, canActivate: [AuthGuard]},
  {path: 'notification', loadChildren: notificationModule},
  {path: 'mainscreen', loadChildren: mainScreenModule},
  {path: 'call-couriers', loadChildren: callCouriersModule, canActivate: [AuthGuard]},
  {path: 'statistics', loadChildren: statisticsModule, canActivate: [AuthGuard]},
  {path: 'app-settings', loadChildren: appSettingsModule, canActivate: [AuthGuard]},
  {path: 'management', loadChildren: managementModule, canActivate: [AuthGuard]},
  {path: '401', component: Page401Component},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
