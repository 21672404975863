import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {GenericResponse} from '../_models/generic-response';
import {environment} from '../../environments/environment';
import {Dashboard} from '../_models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) {
  }

  getDashboard(): Observable<GenericResponse<Dashboard>> {
    return this.httpClient.get<GenericResponse<Dashboard>>(`${environment.getDashboard}`);
  }

  getPaymentReminding(): Observable<GenericResponse<any>> {
    return this.httpClient.get<GenericResponse<any>>(`${environment.getPaymentReminding}`);
  }

  getOverPaymentReminding(): Observable<GenericResponse<any>> {
    return this.httpClient.get<GenericResponse<any>>(`${environment.getOverPaymentReminding}`);
  }

}
