import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountService} from '../_service/account.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenValue = this.accountService.tokenValue;
    const exist = tokenValue && tokenValue.accessToken;

    if (exist) {
      const cloned = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${tokenValue.accessToken}`)
      });

      // @ts-ignore
      return next.handle(cloned).pipe(catchError(err => {
        if ([401, 403].includes(err.status)) {
          this.accountService.logout();
        }
      }));

    }

    return next.handle(request);
  }
}
