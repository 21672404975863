<!-- Begin page -->
<div *ngIf="token" id="wrapper">

  <!-- Topbar Start -->
  <div class="navbar-custom">

    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">
        <li class="notification-list topbar-dropdown" *ngIf="comingPayList.length > 0 || overPayList.length > 0">
          <div class="">
            <a class="nav-link waves-effect waves-light pulseAnimColor position-relative p-0" style="top:0"
               (click)="paymentDialog()">
              <ng-lottie [options]="optionsLottie" style="display: inline-block" height="40px"></ng-lottie>
              <span class="mobil-hide"> Verilmemiş hakedişler var </span></a>
          </div>
        </li>
        <li class="dropdown notification-list topbar-dropdown">
          <a (click)=markAsRead() aria-expanded="false" aria-haspopup="false"
             class="nav-link dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
             href="#" role="button">
            <i class="fe-bell noti-icon"></i>
            <span class="badge badge-danger rounded-circle noti-icon-badge"
                  *ngIf="notificationCount>0">{{notificationCount}}</span>
            <!--            <ng-template #notifiCount>-->
            <!--              <span class="badge badge-danger rounded-circle noti-icon-badge">0</span>-->
            <!--            </ng-template>-->
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-lg" style="min-width: 460px;">

            <!-- item-->
            <div class="dropdown-item noti-title">
              <h5 class="m-0">
                <span class="float-right">
                  <a (click)="markAsRead()" class="text-dark">
                    <small style="cursor: pointer; color: #0d9b54">Okundu olarak işaretle</small>
                  </a>
                </span>Bildirimler
              </h5>
            </div>


            <div *ngFor="let noti of notifications" class="noti-scroll" data-simplebar>

              <!-- item-->
              <a (click)="notificationById(noti.id)" [ngClass]="noti.isRead ? '': 'newnotifi'"
                 class="dropdown-item mb-1"
                 href="javascript:void(0);">
                <div class="d-flex align-items-center" routerLink="/order">
                  <div>
                    <i class="far fa-dot-circle"></i>
                  </div>
                  <div class="d-flex flex-column ml-2">
                    <div>
                      {{noti.fullNotification}}
                    </div>
                    <div class="text-muted fs-10">
                      {{noti.createdAt | date:'dd/MM/yy HH:mm':'UTC':'TR'}}
                    </div>
                  </div>
                </div>

              </a>
            </div>

            <!-- All-->
            <a class="dropdown-item text-center text-primary notify-item notify-all" href="javascript:void(0);"
               routerLink="/notification">
              Tümü
              <i class="fe-arrow-right"></i>
            </a>

          </div>
        </li>
        <li class="dropdown notification-list topbar-dropdown">
          <a aria-expanded="false" aria-haspopup="false"
             class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
             data-toggle="dropdown" href="#" role="button">
            <img alt="user-image" class="rounded-circle" src="{{imageUrl}}">
            <span class="pro-user-name ml-1">
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
            <!-- item-->
            <div class="dropdown-header noti-title">
              <h4 class="text-overflow m-0">Hoşgeldiniz !</h4>
              {{name}}
            </div>

            <!-- item-->
            <a class="dropdown-item notify-item" href="javascript:void(0);">
              <i class="fe-user"></i>
              <span>Hesabım</span>
            </a>


            <!-- item-->
            <a (click)="logout()" class="dropdown-item notify-item" style="cursor:pointer;">
              <i class="fe-log-out"></i>
              <span>Çıkış</span>
            </a>

          </div>
        </li>

      </ul>

      <!-- LOGO -->
      <div class="logo-box">
        <a class="logo logo-dark text-center" routerLink="/" routerLinkActive="active">
          <span class="logo-sm">
              <img alt="" height="42" src="../assets/images/logo-sm.png">
          </span>
          <span class="logo-lg">
              <img alt="" height="40" src="../assets/images/logo-dark.png">
          </span>
        </a>

        <a class="logo logo-light text-center" routerLink="/" routerLinkActive="active">
          <span class="logo-sm">
              <img alt="" height="42" src="../assets/images/logo-sm.png">
          </span>
          <span class="logo-lg">
            <img alt="" height="40" src="../assets/images/logo-light.png">
        </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>

          <button class="button-menu-mobile waves-effect waves-light">
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <!-- Mobile menu toggle (Horizontal Layout)-->
          <a class="navbar-toggle nav-link" data-target="#topnav-menu-content" data-toggle="collapse">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
  <!-- end Topbar -->

  <!-- ========== Left Sidebar Start ========== -->
  <div class="left-side-menu">

    <div class="h-100 menuitem-active" data-simplebar>

      <!-- User box -->
      <div class="user-box text-center">
        <img alt="user-img" class="rounded-circle avatar-md" src="{{imageUrl}}"
             title="Mat Helme">
        <div class="dropdown">
          <a class="text-dark dropdown-toggle h5 mt-2 mb-1 d-block" data-toggle="dropdown"
             href="javascript: void(0);">Geneva Kennedy</a>
          <div class="dropdown-menu user-pro-dropdown">

            <!-- item-->
            <a class="dropdown-item notify-item" href="javascript:void(0);">
              <i class="fe-user mr-1"></i>
              <span>Hesabım</span>
            </a>

            <!-- item-->
            <a class="dropdown-item notify-item" href="javascript:void(0);">
              <i class="fe-log-out mr-1"></i>
              <span>Çıkış</span>
            </a>

          </div>
        </div>
        <p class="text-muted">Admin Head</p>
      </div>

      <!--- Sidemenu -->
      <div id="sidebar-menu">

        <ul *ngFor="let item of userRoles" id="side-menu">
          <li *ngFor="let menuItem of item.crmMainSideRelationDtos; let i=index">
            <a *ngIf="menuItem.sideMenus.length > 0; else showTemp" data-toggle="collapse"
               href="#menuitem{{i+menuItem.crmRoleId+menuItem.path}}">
              <i class="{{menuItem.icon}}"></i>
              <span>{{menuItem.name}}  <span *ngIf="menuItem.badgeStatus"
                                             class="position-absolute circle pulseAnim orangeAnim"></span></span>
              <span class="menu-arrow"></span>
            </a>
            <ng-template #showTemp>
              <a routerLink={{menuItem.route}}>
                <i class={{menuItem.icon}}></i>
                <span>{{menuItem.name}}</span>
              </a>
            </ng-template>

            <div class="collapse" id="menuitem{{i+menuItem.crmRoleId+menuItem.path}}">
              <ul class="nav-second-level">
                <li *ngFor="let subMenu of menuItem.sideMenus">
                  <a routerLink="{{subMenu.route}}">{{subMenu.name}}
                    <span *ngIf="subMenu.notiCount > 0" style="top: 14px"
                          class="position-absolute circle pulseAnim orangeAnim"></span>
                    <span *ngIf="subMenu.notiCount > 0"
                          style="margin-left: 20px; font-size: 10px">({{subMenu.notiCount}})</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>


        </ul>

      </div>
      <!-- End Sidebar -->

      <div class="clearfix"></div>

    </div>
    <!-- Sidebar -left -->

  </div>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start Page Content here -->
  <!-- ============================================================== -->

  <div class="content-page">
    <div class="content">

      <!-- Start Content-->
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div> <!-- container -->

    </div> <!-- content -->

    <!-- Footer Start -->
    <!--    <footer class="footer">-->
    <!--      <div class="container-fluid">-->
    <!--        <div class="row">-->
    <!--          <div class="col-md-6">-->
    <!--            2020 - -->
    <!--            <script>document.write(new Date().getFullYear())</script> &copy; Hızlıgeliyo Developer Teams <a-->
    <!--            href="https://hizligeliyo.com/" target="_blank"> hizligeliyo.com</a> -&#45;&#45; Version:1.0.1-->
    <!--          </div>-->
    <!--          <div class="col-md-6">-->
    <!--            <div class="text-md-right footer-links d-none d-sm-block">-->
    <!--              <a href="javascript:void(0);">Hakkımızda</a>-->
    <!--              <a href="javascript:void(0);">Yardım</a>-->
    <!--              <a href="javascript:void(0);">İletişim</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </footer>-->
    <!-- end Footer -->

  </div>

  <!-- ============================================================== -->
  <!-- End Page content -->
  <!-- ============================================================== -->


</div>
<!-- END wrapper -->

<!-- Right Sidebar -->
<!--<div *ngIf="token" class="right-bar">-->
<!--  <div class="h-100" data-simplebar>-->

<!--    &lt;!&ndash; Nav tabs &ndash;&gt;-->
<!--    <ul class="nav nav-tabs nav-bordered nav-justified" role="tablist">-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link py-2" data-toggle="tab" href="#chat-tab" role="tab">-->
<!--          <i class="mdi mdi-message-text d-block font-22 my-1"></i>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link py-2" data-toggle="tab" href="#tasks-tab" role="tab">-->
<!--          <i class="mdi mdi-format-list-checkbox d-block font-22 my-1"></i>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link py-2 active" data-toggle="tab" href="#settings-tab" role="tab">-->
<!--          <i class="mdi mdi-cog-outline d-block font-22 my-1"></i>-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->

<!--    &lt;!&ndash; Tab panes &ndash;&gt;-->
<!--    <div class="tab-content pt-0">-->
<!--      <div class="tab-pane" id="chat-tab" role="tabpanel">-->

<!--        <form class="search-bar p-3">-->
<!--          <div class="position-relative">-->
<!--            <input class="form-control" placeholder="Search..." type="text">-->
<!--            <span class="mdi mdi-magnify"></span>-->
<!--          </div>-->
<!--        </form>-->

<!--        <h6 class="font-weight-medium px-3 mt-2 text-uppercase">Group Chats</h6>-->

<!--        <div class="p-2">-->
<!--          <a class="text-reset notification-item pl-3 mb-2 d-block" href="javascript: void(0);">-->
<!--            <i class="mdi mdi-checkbox-blank-circle-outline mr-1 text-success"></i>-->
<!--            <span class="mb-0 mt-1">App Development</span>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item pl-3 mb-2 d-block" href="javascript: void(0);">-->
<!--            <i class="mdi mdi-checkbox-blank-circle-outline mr-1 text-warning"></i>-->
<!--            <span class="mb-0 mt-1">Office Work</span>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item pl-3 mb-2 d-block" href="javascript: void(0);">-->
<!--            <i class="mdi mdi-checkbox-blank-circle-outline mr-1 text-danger"></i>-->
<!--            <span class="mb-0 mt-1">Personal Group</span>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item pl-3 d-block" href="javascript: void(0);">-->
<!--            <i class="mdi mdi-checkbox-blank-circle-outline mr-1"></i>-->
<!--            <span class="mb-0 mt-1">Freelance</span>-->
<!--          </a>-->
<!--        </div>-->

<!--        <h6 class="font-weight-medium px-3 mt-3 text-uppercase">Favourites <a class="font-18 text-danger"-->
<!--                                                                              href="javascript: void(0);"><i-->
<!--          class="float-right mdi mdi-plus-circle"></i></a></h6>-->

<!--        <div class="p-2">-->
<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status online"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Andrew Mackie</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">It will seem like simplified English.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status away"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Rory Dalyell</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">To an English person, it will seem like simplified</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status busy"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Jaxon Dunhill</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">To achieve this, it would be necessary.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->

<!--        <h6 class="font-weight-medium px-3 mt-3 text-uppercase">Other Chats <a class="font-18 text-danger"-->
<!--                                                                               href="javascript: void(0);"><i-->
<!--          class="float-right mdi mdi-plus-circle"></i></a></h6>-->

<!--        <div class="p-2 pb-4">-->
<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status online"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Jackson Therry</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">Everyone realizes why a new common language.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status away"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Charles Deakin</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">The languages only differ in their grammar.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status online"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Ryan Salting</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">If several languages coalesce the grammar of the resulting.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status online"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Sean Howse</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">It will seem like simplified English.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status busy"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Dean Coward</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">The new common language will be more simple.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset notification-item" href="javascript: void(0);">-->
<!--            <div class="media">-->
<!--              <div class="position-relative mr-2">-->
<!--                <img alt="user-pic" class="rounded-circle avatar-sm" src="{{imageUrl}}">-->
<!--                <i class="mdi mdi-circle user-status away"></i>-->
<!--              </div>-->
<!--              <div class="media-body overflow-hidden">-->
<!--                <h6 class="mt-0 mb-1 font-14">Hayley East</h6>-->
<!--                <div class="font-13 text-muted">-->
<!--                  <p class="mb-0 text-truncate">One could refuse to pay expensive translators.</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <div class="text-center mt-3">-->
<!--            <a class="btn btn-sm btn-white" href="javascript:void(0);">-->
<!--              <i class="mdi mdi-spin mdi-loading mr-2"></i>-->
<!--              Load more-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="tab-pane" id="tasks-tab" role="tabpanel">-->
<!--        <h6 class="font-weight-medium p-3 m-0 text-uppercase">Working Tasks</h6>-->
<!--        <div class="px-2">-->
<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">App Development<span class="float-right">75%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="75" class="progress-bar bg-success"-->
<!--                   role="progressbar" style="width: 75%"></div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">Database Repair<span class="float-right">37%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="37" class="progress-bar bg-info"-->
<!--                   role="progressbar" style="width: 37%"></div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">Backup Create<span class="float-right">52%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="52" class="progress-bar bg-warning"-->
<!--                   role="progressbar" style="width: 52%"></div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->

<!--        <h6 class="font-weight-medium px-3 mb-0 mt-4 text-uppercase">Upcoming Tasks</h6>-->

<!--        <div class="p-2">-->
<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">Sales Reporting<span class="float-right">12%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="12" class="progress-bar bg-danger"-->
<!--                   role="progressbar" style="width: 12%"></div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">Redesign Website<span class="float-right">67%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="67" class="progress-bar bg-primary"-->
<!--                   role="progressbar" style="width: 67%"></div>-->
<!--            </div>-->
<!--          </a>-->

<!--          <a class="text-reset item-hovered d-block p-2" href="javascript: void(0);">-->
<!--            <p class="text-muted mb-0">New Admin Design<span class="float-right">84%</span></p>-->
<!--            <div class="progress mt-2" style="height: 4px;">-->
<!--              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="84" class="progress-bar bg-success"-->
<!--                   role="progressbar" style="width: 84%"></div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->

<!--        <div class="p-3 mt-2">-->
<!--          <a class="btn btn-success btn-block waves-effect waves-light" href="javascript: void(0);">Create Task</a>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div class="tab-pane active" id="settings-tab" role="tabpanel">-->
<!--        <h6 class="font-weight-medium px-3 m-0 py-2 font-13 text-uppercase bg-light">-->
<!--          <span class="d-block py-1">Theme Settings</span>-->
<!--        </h6>-->

<!--        <div class="p-3">-->
<!--          <div class="alert alert-warning" role="alert">-->
<!--            <strong>Customize </strong> the overall color scheme, sidebar menu, etc.-->
<!--          </div>-->

<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Color Scheme</h6>-->
<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="light-mode-check" name="color-scheme-mode"-->
<!--                   type="radio" value="light"/>-->
<!--            <label class="custom-control-label" for="light-mode-check">Light Mode</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="dark-mode-check" name="color-scheme-mode" type="radio"-->
<!--                   value="dark"/>-->
<!--            <label class="custom-control-label" for="dark-mode-check">Dark Mode</label>-->
<!--          </div>-->

<!--          &lt;!&ndash; Width &ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Width</h6>-->
<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="fluid-check" name="width" type="radio" value="fluid"/>-->
<!--            <label class="custom-control-label" for="fluid-check">Fluid</label>-->
<!--          </div>-->
<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="boxed-check" name="width" type="radio" value="boxed"/>-->
<!--            <label class="custom-control-label" for="boxed-check">Boxed</label>-->
<!--          </div>-->

<!--          &lt;!&ndash; Menu positions &ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Menus (Leftsidebar and Topbar) Positon</h6>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="fixed-check" name="menus-position" type="radio"-->
<!--                   value="fixed"/>-->
<!--            <label class="custom-control-label" for="fixed-check">Fixed</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="scrollable-check" name="menus-position" type="radio"-->
<!--                   value="scrollable"/>-->
<!--            <label class="custom-control-label" for="scrollable-check">Scrollable</label>-->
<!--          </div>-->

<!--          &lt;!&ndash; Left Sidebar&ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Left Sidebar Color</h6>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="light-check" name="leftsidebar-color" type="radio"-->
<!--                   value="light"/>-->
<!--            <label class="custom-control-label" for="light-check">Light</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="dark-check" name="leftsidebar-color" type="radio" value="dark"/>-->
<!--            <label class="custom-control-label" for="dark-check">Dark</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="brand-check" name="leftsidebar-color" type="radio" value="brand"/>-->
<!--            <label class="custom-control-label" for="brand-check">Brand</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-3">-->
<!--            <input class="custom-control-input" id="gradient-check" name="leftsidebar-color" type="radio"-->
<!--                   value="gradient"/>-->
<!--            <label class="custom-control-label" for="gradient-check">Gradient</label>-->
<!--          </div>-->

<!--          &lt;!&ndash; size &ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Left Sidebar Size</h6>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="default-size-check" name="leftsidebar-size"-->
<!--                   type="radio" value="default"/>-->
<!--            <label class="custom-control-label" for="default-size-check">Default</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="condensed-check" name="leftsidebar-size" type="radio"-->
<!--                   value="condensed"/>-->
<!--            <label class="custom-control-label" for="condensed-check">Condensed <small>(Extra Small-->
<!--              size)</small></label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="compact-check" name="leftsidebar-size" type="radio"-->
<!--                   value="compact"/>-->
<!--            <label class="custom-control-label" for="compact-check">Compact <small>(Small size)</small></label>-->
<!--          </div>-->

<!--          &lt;!&ndash; User info &ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Sidebar User Info</h6>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="sidebaruser-check" name="leftsidebar-user" type="checkbox"-->
<!--                   value="fixed"/>-->
<!--            <label class="custom-control-label" for="sidebaruser-check">Enable</label>-->
<!--          </div>-->


<!--          &lt;!&ndash; Topbar &ndash;&gt;-->
<!--          <h6 class="font-weight-medium font-14 mt-4 mb-2 pb-1">Topbar</h6>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input checked class="custom-control-input" id="darktopbar-check" name="topbar-color" type="radio"-->
<!--                   value="dark"/>-->
<!--            <label class="custom-control-label" for="darktopbar-check">Dark</label>-->
<!--          </div>-->

<!--          <div class="custom-control custom-switch mb-1">-->
<!--            <input class="custom-control-input" id="lighttopbar-check" name="topbar-color" type="radio" value="light"/>-->
<!--            <label class="custom-control-label" for="lighttopbar-check">Light</label>-->
<!--          </div>-->


<!--          <button class="btn btn-primary btn-block mt-4" id="resetBtn">Reset to Default</button>-->

<!--          <a class="btn btn-danger btn-block mt-3"-->
<!--             href="https://1.envato.market/uboldadmin" target="_blank"><i class="mdi mdi-basket mr-1"></i> Purchase Now</a>-->

<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

<!--  </div> &lt;!&ndash; end slimscroll-menu&ndash;&gt;-->
<!--</div>-->
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div *ngIf="token" class="rightbar-overlay"></div>

<div *ngIf="!token" class="content-page">
  <div class="content">

    <!-- Start Content-->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div> <!-- container -->

  </div> <!-- content -->
</div>

<p-dialog [(visible)]="dialogPayment" [modal]="true"
          [style]="{width: '600px'}"
          header="Hakediş bekleyen siparişler"
          styleClass="p-fluid">
  <ng-template pTemplate="content">

    <div *ngIf="overPayList.length > 0" class="alert alert-danger d-flex align-items-center small p-1">
      <ng-lottie [options]="optionsLottie" height="48px"></ng-lottie>
      <a class="text-dark"> {{overPayList.length}}
        adet verilmemiş hakediş ve süresi geçmiş sipariş mevcut
      </a>
    </div>
    <span *ngFor="let item of overPayList">
        <a class="badge badge-danger mr-1 cursor-pointer text-white" (click)="detailPayment(item)"> #{{item}} </a>
    </span>

    <table class="table table-striped table-sm table-bordered" *ngIf="comingPayList.length > 0">
      <thead>
      <th colspan="2" class="text-warning">
        Hakediş verilmesi gereken ve 5 günden daha az süresi kalan siparişler
      </th>
      </thead>
      <thead>
      <th>Sipariş No</th>
      <th class="text-center" style="width: 1rem">Detay</th>
      </thead>
      <tbody>
      <tr *ngFor="let item of comingPayList">
        <td>
          <span class="text-dark font-weight-semibold">#{{item}}</span>
        </td>
        <td>
          <button class="btn btn-sm btn-info" (click)="detailPayment(item)"><i
            class="far fa-edit"></i></button>
        </td>
      </tr>
    </table>
  </ng-template>
</p-dialog>
